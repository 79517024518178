import { ScrambleText } from '../components/ui/ScrambleText';
import BigShift from './BigShift';
import WhoWeAre from './WhoWeAre';
import Neo from './Neo';
import JoinUs from './JoinUs';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ChevronDown } from '../components/ui/ChevronDown';

const HomePage = () => {
  const location = useLocation();
  const [currentSection, setCurrentSection] = useState('landing');

  const scrollToNextSection = (currentId) => {
    const mainContainer = document.getElementById('main-container');
    const sections = ['landing', 'big-shift', 'who-we-are', 'neo', 'join-us'];
    const currentIndex = sections.indexOf(currentId);
    const nextSection = sections[currentIndex + 1];
    
    if (nextSection) {
      const element = document.getElementById(nextSection);
      if (element && mainContainer) {
        mainContainer.scrollTo({
          top: element.offsetTop,
          behavior: 'smooth'
        });
        setCurrentSection(nextSection);
      }
    }
  };

  useEffect(() => {
    if (location.state?.scrollTo) {
      const mainContainer = document.getElementById('main-container');
      const element = document.getElementById(location.state.scrollTo);
      
      if (element && mainContainer) {
        setTimeout(() => {
          mainContainer.scrollTo({
            top: element.offsetTop,
            behavior: 'smooth'
          });
        }, 100);
      }
    }
  }, [location.state]);

  // Add scroll event listener to track current section
  useEffect(() => {
    const mainContainer = document.getElementById('main-container');
    if (!mainContainer) return;

    const handleScroll = () => {
      const sections = ['landing', 'big-shift', 'who-we-are', 'neo', 'join-us'];
      const mainContainer = document.getElementById('main-container');
      const scrollPosition = mainContainer.scrollTop + (window.innerHeight / 3);

      for (const section of sections) {
        const element = document.getElementById(section);
        if (!element) continue;
        
        const offsetTop = element.offsetTop;
        const offsetBottom = offsetTop + element.offsetHeight;
        
        if (scrollPosition >= offsetTop && scrollPosition < offsetBottom) {
          setCurrentSection(section);
          break;
        }
      }
    };

    mainContainer.addEventListener('scroll', handleScroll);
    return () => mainContainer.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <main className="relative h-screen bg-neutral-950">
      {/* Grid background */}
      <div className="absolute inset-0 bottom-0 left-0 right-0 top-0 bg-[linear-gradient(to_right,#27272733_1px,transparent_1px),linear-gradient(to_bottom,#27272733_1px,transparent_1px)] bg-[size:84px_84px] [mask-image:radial-gradient(ellipse_60%_50%_at_50%_0%,#000_70%,transparent_100%)]"></div>

      <div className="relative h-full snap-container snap-y snap-mandatory overflow-y-auto" id="main-container">
        <section id="landing" className="h-screen pb-24 w-full snap-start flex flex-col items-center justify-center relative -mt-20">
          <div className="max-w-[90rem] w-full mx-auto text-center px-5">
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, ease: "easeOut" }}
            >
              <h1 className="text-5xl sm:text-6xl xl:text-7xl font-bold font-mono mb-4 tracking-tighter leading-tight">
                <ScrambleText text="Moving Humans Forward" className="text-[#DBDBDB]" />
              </h1>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2, ease: "easeOut" }}
            >
              <p className="text-base sm:text-lg md:text-xl lg:text-xl mb-8 text-[#4F6A63] font-mono whitespace-nowrap pt-2">
                <ScrambleText
                  text="We are pioneering a fundamental shift in how AI amplifies your potential"
                  delay={200}
                />
              </p>
            </motion.div>
          </div>
        </section>

        <BigShift id="big-shift" />
        <WhoWeAre id="who-we-are" />
        <Neo id="neo" />
        <JoinUs id="join-us" />
      </div>
      <ChevronDown 
        onClick={() => scrollToNextSection(currentSection)}
        currentSection={currentSection}
      />
    </main>
  );
};

export default HomePage;
