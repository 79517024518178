import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { CiLinkedin } from 'react-icons/ci';
import { ScrambleText } from '../components/ui/ScrambleText';


const AboutPage = () => {
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    return (
        <>
            <main className="w-full overflow-x-hidden pt-16 bg-neutral-950">
                <section className="max-w-7xl mx-auto px-8 sm:px-8 md:px-24 lg:px-32 py-16 sm:py-2 mt-20">
                    <div>
                        {/* Section 1: About Maincode */}
                        <motion.h1
                            className="text-2xl sm:text-4xl font-bold mb-4 tracking-tight leading-tight"
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, ease: "easeOut", delay: 0.2 }}
                        >
                            <ScrambleText 
                                text="About Maincode"
                                className="text-[#DBDBDB]"
                                enabled={isMounted}
                            />
                        </motion.h1>

                        <motion.h2
                            className="text-lg sm:text-lg font-bold mb-4 tracking-tight leading-tight text-[#00CF9D] pt-3"
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, ease: "easeOut", delay: 0.4 }}
                        >
                            <ScrambleText 
                                text="Work isn't just thinking. It's execution."
                                className="text-[#00CF9D]"
                                delay={200}
                                enabled={isMounted}
                            />
                        </motion.h2>

                        <motion.div
                            className="mb-8"
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, ease: "easeOut", delay: 0.6 }}
                        >
                            <p className="text-sm sm:text-md body-text font-mono mb-4 text-container text-[#4F6A63]">
                                <ScrambleText 
                                    text="But as our tools and data sources multiply, the effort required to assemble context, determine intent, and take action has become overwhelming."
                                    delay={300}
                                    enabled={isMounted}
                                />
                            </p>
                            <p className="text-sm sm:text-md body-text font-mono mb-4 text-container text-[#4F6A63]">
                                <ScrambleText 
                                    text="Before work can move forward, we spend too much time getting oriented, retrieving information, cross-referencing systems, and manually translating understanding into execution. The systems we rely on were built to store information, not to act on it. This gap between data and execution is what holds us back."
                                    delay={400}
                                    enabled={isMounted}
                                />
                            </p>
                            <p className="text-sm sm:text-md body-text font-mono mb-4 text-container text-[#4F6A63]">
                                <ScrambleText 
                                    text="Maincode is leading a new era of AI-native technology, one that doesn't just gather and interpret context but understands, adapts, and acts. We are redefining how software works with you, synthesizing fragmented information, inferring intent, and turning insight into action instantly."
                                    delay={500}
                                    enabled={isMounted}
                                />
                            </p>
                            <p className="text-sm sm:text-md body-text font-mono mb-4 text-container text-[#4F6A63]">
                                <ScrambleText 
                                    text="This isn't just about automation. It's augmentation. Instead of replacing legacy systems, we extend and control them, transforming passive interfaces into intelligent, action-driven environments. Software moves faster when it doesn't just store information. It understands, adapts, and executes."
                                    delay={600}
                                    enabled={isMounted}
                                />
                            </p>
                            <p className="text-sm sm:text-md body-text font-mono text-container text-[#4F6A63]">
                                <ScrambleText 
                                    text="And that is the future of work. Not complexity, but intelligence that works with us, amplifying human capability and unlocking what is possible."
                                    delay={700}
                                    enabled={isMounted}
                                />
                            </p>
                        </motion.div>
                        </div>

                        {/* Section 2 */}
                        <div>
                            <motion.h2
                                className="text-lg sm:text-lg font-bold mb-4 tracking-tight leading-tight text-[#00CF9D] pt-3"
                                initial={{ opacity: 0, y: 10 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, ease: "easeOut", delay: 0.2 }}
                            >
                                <ScrambleText 
                                    text="Technology needs to evolve in motion. But companies have become static."
                                    className="text-[#00CF9D]"
                                    enabled={isMounted}
                                />
                            </motion.h2>
                            <motion.div
                                className="mb-8"
                                initial={{ opacity: 0, y: 10 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, ease: "easeOut", delay: 0.2 }}
                            >
                                <p className="text-sm sm:text-md body-text font-mono mb-4 text-container text-[#4F6A63]">
                                    <ScrambleText 
                                        text="For most of human history, the tools we used were simple extensions of our own capabilities. Then, in the last century, software transformed the world, not by automating work, but by organizing it. The systems we rely on today were built for an era when storing and retrieving data was revolutionary. That era is over."
                                        delay={100}
                                        enabled={isMounted}
                                    />
                                </p>
                                <p className="text-sm sm:text-md body-text font-mono mb-4 text-container text-[#4F6A63]">
                                    <ScrambleText 
                                        text="Today, intelligence is no longer artificial. It is embedded. It does not just store information. It understands, anticipates, and acts. We now stand at the dawn of truly general intelligence."
                                        delay={200}
                                        enabled={isMounted}
                                    />
                                </p>
                                <p className="text-sm sm:text-md body-text font-mono mb-4 text-container text-[#4F6A63]">
                                    <ScrambleText 
                                        text="But reinvention isn't just about the tools. It's about how we build."
                                        delay={300}
                                        enabled={isMounted}
                                    />
                                </p>
                                <p className="text-sm sm:text-md body-text font-mono mb-4 text-container text-[#4F6A63]">
                                    <ScrambleText 
                                        text="Technology must evolve in motion, in real time, embedded in the world, shaping itself to the work at hand. Yet the companies that build it have become slow, bloated, and performative. Meetings, process, and corporate theater have replaced momentum, ownership, and invention. Culture has been reduced to perks and platitudes, infantilization disguised as team spirit."
                                        delay={400}
                                        enabled={isMounted}
                                    />
                                </p>
                                <p className="text-sm sm:text-md body-text font-mono text-container text-[#4F6A63]">
                                    <ScrambleText 
                                        text="We reject all of it because great technology is built by builders, not bureaucracies. At Maincode, everything starts with the builder. The ones who create, who shape, who push. The ones who move fast and take responsibility for what they ship. If you don't build, you don't belong. We are here to create technology that matters, that customers love, that moves humans forward."
                                        delay={500}
                                        enabled={isMounted}
                                    />
                                </p>
                            </motion.div>
                        </div>

                        {/* Section 3 */}
                        <div>
                            <motion.h2
                                className="text-lg sm:text-xl font-bold mb-4 tracking-tight leading-tight text-[#00CF9D] pt-3"
                                initial={{ opacity: 0, y: 10 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, ease: "easeOut", delay: 1.2 }}
                            >
                                <ScrambleText 
                                    text="We are here to do something incredible." 
                                    className="text-[#00CF9D]"
                                    enabled={isMounted}
                                />
                            </motion.h2>
                            <motion.div
                                className="mb-8"
                                initial={{ opacity: 0, y: 10 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, ease: "easeOut", delay: 1.4 }}
                            >
                                <p className="text-sm sm:text-md body-text font-mono mb-4 text-container text-[#4F6A63]">
                                    <ScrambleText 
                                        text="Not just ship software. Not just start a company. We want to push what's possible, redefine AI-native technology, and build something that moves humans forward. And we want to do it here, in Melbourne, our home."
                                        delay={100}
                                        enabled={isMounted}
                                    />
                                </p>
                                <p className="text-sm sm:text-md body-text font-mono mb-4 text-container text-[#4F6A63]">
                                    <ScrambleText 
                                        text="Because this kind of work doesn't happen alone. It takes a certain kind of person, someone who builds because they have to, because they can't imagine doing anything else."
                                        delay={200}
                                        enabled={isMounted}
                                    />
                                </p>
                                <p className="text-sm sm:text-md body-text font-mono mb-4 text-container text-[#4F6A63]">
                                    <ScrambleText 
                                        text="We are not a company for those who settle. Not for those who say she'll be right when they know it won't be. We are for those who believe tall poppies should stand as high as Redwoods."
                                        delay={300}
                                        enabled={isMounted}
                                    />
                                </p>
                                <p className="text-sm sm:text-md body-text font-mono mb-4 text-container text-[#4F6A63]">
                                    <ScrambleText 
                                        text="We build because we love it. Not because someone pays us to. Not because it's easy. We build because it's what we do."
                                        delay={400}
                                        enabled={isMounted}
                                    />
                                </p>
                                <p className="text-sm sm:text-md body-text font-mono text-container text-[#4F6A63] leading-relaxed">
                                    <ScrambleText 
                                        text="And we know this kind of work doesn't happen alone. It takes a certain kind of person, someone who builds because they have to, because they can't imagine doing anything else. The ones who don't wait to be told. The ones who see the gap and build what's missing. The ones who chase the problem, not the credit. They aren't measured by job titles, fake multipliers, or abstract accolades. They are measured by their peers, by those who have seen them ship, solve impossible problems, and push beyond what seemed possible. But too often, those builders find themselves trapped in places that don't deserve them. And if they're not here now, we know where they are. Punching tickets. Sitting through another one-on-one. Watching the magic of building slip further away. And yet, they remember. They remember when everything aligned, when the right people, the right project, and the right moment collided. When they felt the rush of a once-in-a-lifetime sprint where you throw in everything because you know you'll never get another shot at this exact moment again. Because it was never just about the job. It was about the build. The challenge. The pursuit of something truly great. They don't want another job. They want to feel it again."
                                        delay={500}
                                        enabled={isMounted}
                                    />
                                </p>
                            </motion.div>
                        </div>

                        {/* Section 4: Neo The Next Leap in AI */}
                        <div>
                            <motion.h2
                                className="text-lg sm:text-xl font-bold mb-4 tracking-tight leading-tight text-[#00CF9D] pt-3"
                                initial={{ opacity: 0, y: 10 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, ease: "easeOut", delay: 1.6 }}
                            >
                                <ScrambleText 
                                    text="Neo The Next Leap in AI" 
                                    className="text-[#00CF9D]"
                                    enabled={isMounted}
                                />
                            </motion.h2>
                            <motion.div
                                className="mb-12"
                                initial={{ opacity: 0, y: 10 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, ease: "easeOut", delay: 1.8 }}
                            >
                                <p className="text-sm sm:text-md body-text font-mono mb-4 text-container text-[#4F6A63]">
                                    <ScrambleText 
                                        text="Software should do more than display information. It should understand, anticipate, and act."
                                        delay={100}
                                        enabled={isMounted}
                                    />
                                </p>
                                <p className="text-sm sm:text-md body-text font-mono mb-4 text-container text-[#4F6A63]">
                                    <ScrambleText 
                                        text="AI has already changed how we generate, summarize, and retrieve knowledge. But knowledge alone doesn't move work forward."
                                        delay={200}
                                        enabled={isMounted}
                                    />
                                </p>
                                <p className="text-sm sm:text-md body-text font-mono mb-4 text-container text-[#4F6A63]">
                                    <ScrambleText 
                                        text="The next leap forward is about action. AI that sees the full picture, infers intent, and executes seamlessly."
                                        delay={300}
                                        enabled={isMounted}
                                    />
                                </p>
                                <p className="text-sm sm:text-md body-text font-mono mb-4 text-container text-[#4F6A63]">
                                    <ScrambleText 
                                        text="Most AI still waits for instructions. It generates, summarizes, suggests, but it doesn't act."
                                        delay={400}
                                        enabled={isMounted}
                                    />
                                </p>
                                <p className="text-sm sm:text-md body-text font-mono mb-4 text-container text-[#4F6A63]">
                                    <ScrambleText 
                                        text="Neo changes that. It's the first AI system designed to move beyond passive knowledge, injecting intelligence directly into the tools you already use to turn understanding into execution."
                                        delay={500}
                                        enabled={isMounted}
                                    />
                                </p>
                                <p className="text-sm sm:text-md body-text font-mono mb-4 text-container text-[#4F6A63]">
                                    <ScrambleText 
                                        text="Neo is an AI-native rebuild of Chromium, transforming the browser from a passive window into an intelligent execution layer."
                                        delay={600}
                                        enabled={isMounted}
                                    />
                                </p>
                                <p className="text-sm sm:text-md body-text font-mono mb-4 text-container text-[#4F6A63]">
                                    <ScrambleText 
                                        text="Traditional browsers were built for consumption. They load pages, render content, and leave the rest to you. But modern work isn't static. It's interactive, dynamic, and fragmented across tools. Navigating between apps, managing workflows, and translating understanding into action is still manual."
                                        delay={700}
                                        enabled={isMounted}
                                    />
                                </p>
                                <p className="text-sm sm:text-md body-text font-mono mb-4 text-container text-[#4F6A63]">
                                    <ScrambleText 
                                        text="Neo changes that. It doesn't just rethink the browser. It injects intelligence directly into the software you use, extracting context, automating tasks, and moving work forward."
                                        delay={800}
                                        enabled={isMounted}
                                    />
                                </p>
                                <p className="text-sm sm:text-md body-text font-mono mb-4 text-container text-[#4F6A63]">
                                    <ScrambleText 
                                        text="We are starting with the browser because it is where modern work happens. But Neo is more than a browser. It is an execution layer for the entire digital world."
                                        delay={900}
                                        enabled={isMounted}
                                    />
                                </p>
                                <p className="text-sm sm:text-md body-text font-mono text-container text-[#4F6A63] leading-relaxed">
                                    <ScrambleText 
                                        text="Neo doesn't just see one application at a time. It sees across them all, drawing connections, understanding workflows, and automating actions that would otherwise take you hours. We are taking one of the most remarkable engineering achievements, Chromium, and rebuilding it AI-native from the ground up. But this is only the first step. The browser is where work happens today, but tomorrow, Neo will go beyond it, integrating intelligence deeper into the software you rely on. Because the future of work isn't about using more tools. It's about tools that work for you. And we are just getting started. Because this isn't just about better software. It's about a future where technology moves as fast as we do."
                                        delay={1000}
                                        enabled={isMounted}
                                    />
                                </p>
                            </motion.div>
                        </div>

                        {/* Apply on LinkedIn CTA */}
                        <motion.div
                            className="mb-8 sm:mb-12"
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, ease: "easeOut", delay: 2.0 }}
                        >
                            <a 
                              href="https://www.linkedin.com/company/maincodehq/jobs/"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="inline-flex h-12 sm:w-auto animate-shimmer items-center justify-center rounded-md border border-[#00CF9D]/10 bg-[#00CF9D]/5 bg-opacity-20 px-6 font-medium text-[#ffffff] transition-colors hover:bg-[#00CF9D]/20 focus:outline-none focus:ring-2 focus:ring-[#00CF9D] focus:ring-offset-2 focus:ring-offset-neutral-100 gap-2 mt-4"
                            >
                                <CiLinkedin className="w-5 h-5" />
                                <span className="ml-2">Apply on LinkedIn</span>
                            </a>
                        </motion.div>
                    
                </section>
            </main>
        </>
    );
};

export default AboutPage;
