'use client'

import React, { useState, useEffect } from 'react';
import { ScrambleText } from '../components/ui/ScrambleText';
import { motion } from 'framer-motion';
import { FeaturesSection } from '../components/features/FeaturesSection';
import CallToActionSection from '../components/CallToActionSection';
const NeoPage = () => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <main className="w-full overflow-x-hidden pt-16 bg-neutral-950">
      {/* Grid background */}
      <div className="absolute inset-0 bottom-0 left-0 right-0 top-0 bg-[linear-gradient(to_right,#27272733_1px,transparent_1px),linear-gradient(to_bottom,#27272733_1px,transparent_1px)] bg-[size:84px_84px] [mask-image:radial-gradient(ellipse_60%_50%_at_50%_0%,#000_70%,transparent_100%)]"></div>

      <section className="max-w-7xl mx-auto px-8 sm:px-8 md:px-24 lg:px-32 py-8 sm:py-2 mt-20">
        {/* Hero Section */}
        <div>
          <motion.div
            className="hidden sm:mb-8 sm:flex sm:justify-center"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, ease: "easeOut" }}
          >
            <div className="relative rounded-full px-3 py-1 text-sm/6 text-[#4F6A63] ring-1 ring-[#16B08B]/10 hover:ring-[#16B08B]/20 h-fit">
              <ScrambleText
                text="Join Our Research Preview – Now Enrolling"
                delay={200}
                enabled={isMounted}
              />
            </div>
          </motion.div>

          <motion.h1
            className="text-4xl font-semibold tracking-tighter text-center text-[#DBDBDB] sm:text-7xl font-mono mb-4"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, ease: "easeOut", delay: 0.2 }}
          >
            <ScrambleText 
              text="Do More Than Browse"
              enabled={isMounted}
            />
          </motion.h1>

          <motion.h2
            className="text-lg text-center font-medium text-[#4F6A63] sm:text-xl/8 font-mono pt-3"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, ease: "easeOut", delay: 0.4 }}
          >
            <ScrambleText 
              text="Neo AI-Supercharges Your Everyday Apps"
              delay={200}
              enabled={isMounted}
            />
          </motion.h2>

          <motion.div
            className="mt-10 flex items-center justify-center gap-x-6"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, ease: "easeOut", delay: 0.6 }}
          >
            {/* <a
              href="https://www.linkedin.com/company/maincodehq/jobs/"
              target="_blank"
              rel="noopener noreferrer"
              className="rounded-md bg-[#16B08B]/10 px-3.5 py-2.5 text-sm font-semibold text-[#DBDBDB] shadow-xs hover:bg-[#16B08B]/20 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#16B08B] border border-[#16B08B]/20 transition-colors duration-200"
            >
              Apply for early access
            </a> */}
          </motion.div>

          <motion.div
            className="flex justify-center items-center pt-8 sm:pt-10"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.8, ease: "easeOut" }}
          >
            <img 
              src="/neo-hero-img.png" 
              alt="Neo Hero" 
              className="w-[1200px]"
            />
          </motion.div>
        </div>

        {/* Features Section */}
        <div id="features" className="w-full">
          <FeaturesSection />
        </div>

        <CallToActionSection />
      </section>


      
    </main>
  );
};

export default NeoPage;
            