import React, { useState } from 'react';
import { motion, useInView } from 'framer-motion';
import { Link } from 'react-router-dom';
import { ScrambleText } from '../components/ui/ScrambleText';
import { CiLinkedin } from "react-icons/ci";

export default function JoinUs({ id }) {
  const ref = React.useRef(null);
  const isInView = useInView(ref, { margin: '-50% 0px -50% 0px' });
  const [resultMessage] = useState('');

  return (
    <motion.section
      id={id}
      ref={ref}
      className="px-6 flex justify-center items-center min-h-screen py-20 snap-start"
      initial={{ opacity: 0, y: 50 }}
      animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
      transition={{ duration: 0.5, ease: "easeOut" }}
    >
      <div className="text-center max-w-2xl mx-auto">
        <h2 className="px-3 py-1 rounded-full text-xs font-bold bg-[#16B08B]/20 text-[#16B08B] uppercase mb-5 w-fit mx-auto">
          <ScrambleText text="JOIN US" enabled={isInView} />
        </h2>

        <motion.h2
          className="text-3xl md:text-4xl font-extrabold text-white leading-tight mb-5 text-center"
          initial={{ opacity: 0, y: 20 }}
          animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
          transition={{ duration: 0.4, ease: "easeOut", delay: 0.2 }}
        >
          <ScrambleText text="This feels like 2 AM in the lab" enabled={isInView} />
        </motion.h2>

        <motion.p
          className="text-base text-[#4F6A63] font-mono leading-loose text-center"
          initial={{ opacity: 0, y: 20 }}
          animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
          transition={{ duration: 0.5, ease: "easeOut", delay: 0.3 }}
        >
          <ScrambleText text="We care about making incredible memories as much as game-changing technology. If you're yearning for that time when it just felt right, we know the feeling" enabled={isInView} />
        </motion.p>

        <motion.div
          className="mt-8 flex flex-col items-center gap-4"
          initial={{ opacity: 0, y: 20 }}
          animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
          transition={{ duration: 0.5, ease: "easeOut", delay: 0.4 }}
        >
          {/* Primary CTA: Apply on LinkedIn */}
          <a 
            href="https://www.linkedin.com/company/maincodehq/jobs/"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex h-12 items-center justify-center rounded-md border border-[#00CF9D]/10 bg-[#00CF9D]/5 bg-opacity-20 px-6 font-medium text-[#ffffff] transition-colors hover:bg-[#00CF9D]/20 focus:outline-none focus:ring-2 focus:ring-[#00CF9D] focus:ring-offset-2 focus:ring-offset-neutral-100 gap-2"
          >
            <CiLinkedin className="w-5 h-5" />
            Apply on LinkedIn
          </a>

          {/* Secondary CTA: Text Link */}
          <Link 
            to="/about-us"
            className="text-sm md:text-base font-mono text-[#00CF9D] hover:underline pt-4"
          >
            Want the full picture? Explore our vision.
          </Link>
        </motion.div>

        {resultMessage && (
          <div className="mt-4 text-center text-white">
            {resultMessage}
          </div>
        )}
      </div>
    </motion.section>
  );
}
