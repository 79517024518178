import React from 'react';
import { motion, useInView } from 'framer-motion';
import { ScrambleText } from '../components/ui/ScrambleText';
import { useRive } from "rive-react";

export default function Neo({ id, onScrollDown }) {
    const ref = React.useRef(null);
    const isInView = useInView(ref, { margin: '-50% 0px -50% 0px' });

    const { rive, RiveComponent } = useRive({
        src: "/neo.riv",
        autoplay: true,
        stateMachines: "State Machine 1",
        onLoad: () => {
            rive?.play();
        },
    });

    return (
        <motion.section 
            id={id}
            ref={ref}
            className="text-neutral-400 body-font w-full md:px-20 flex items-center text-center justify-center min-h-screen snap-start relative"
            initial={{ opacity: 0, y: 0 }}
            animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 0 }}
            transition={{ duration: 0.2, ease: "easeIn" }}
        >
            <div className=" -mt-28 py-8 px-4 mx-auto sm:py-12">
                <div className="w-[200px] h-[200px] sm:w-[240px] sm:h-[240px] md:h-[320px] md:w-[320px] mx-auto mb-2">
                    <RiveComponent className="w-full h-full" />
                </div>
                <div className="max-w-screen-lg mx-auto">
                    <h2 className="mb-4 text-5xl tracking-tight font-bold text-white">
                        <ScrambleText text="NE0" enabled={isInView} />
                    </h2>
                    <p className="mb-6 font-light text-[#4F6A63] sm:text-xl">
                        <ScrambleText text="The AI-Native Interface for Work" enabled={isInView} />
                    </p>
                    <div className="flex justify-center">
                        <h2 className="px-3 py-1 rounded-lg text-xs font-medium bg-[#16B08B]/5 text-[#7F9892] tracking-tight title-font w-fit">
                            <ScrambleText text="COMING SOON" enabled={isInView} />
                        </h2>
                    </div>

                </div>
            </div>
        </motion.section>
    );
}
