import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/layout/Layout';
import HomePage from './pages/HomePage';
import AboutUs from './pages/OurVision';
import BuildersManifesto from './pages/BuildersManifesto';
import NeoPage from './pages/NeoPage';
function App() {
  return (
    <Router>

        <Routes>
          <Route element={<Layout />}>
            <Route index element={<HomePage />} />
            <Route path="neo" element={<NeoPage />} />
            <Route path="about-us" element={<AboutUs />} />
            <Route path="builders-manifesto" element={<BuildersManifesto />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Route>
        </Routes>
    </Router>
  );
}

export default App;