import { useEffect, useRef, useState, useMemo } from 'react';

const CHARACTERS = '!<>-_\\/[]{}—=+*^?#@%&';
const GLITCH_CHARS = '`¡™£¢∞§¶•ªº–≠åß∂ƒ©˙∆˚¬…æ≈ç√∫˜µ≤≥÷';

export const ScrambleText = ({
  text,
  className = '',
  delay = 0,
  scrambleDuration = 650,
  scrambleSpeed = 45,
  glitchIntensity = 0.02,
  enabled = true,
  renderText = null
}) => {
  const [displayText, setDisplayText] = useState(text);
  const [glitchText, setGlitchText] = useState('');
  const [hasScrambled, setHasScrambled] = useState(false);
  const isScramblingRef = useRef(false);
  const scrambleIntervalRef = useRef(null);
  const glitchIntervalRef = useRef(null);

  const finalLength = useMemo(() => text.length, [text]);

  const getRandomChar = (useGlitch = false) => {
    const chars = useGlitch ? GLITCH_CHARS : CHARACTERS;
    return chars[Math.floor(Math.random() * chars.length)];
  };

  // Trigger a glitch effect with a chance based on glitchIntensity.
  const createGlitchEffect = () => {
    if (Math.random() > glitchIntensity || !isScramblingRef.current) return;
    const glitchStr = text
      .split('')
      .map((char) => (char === ' ' ? ' ' : Math.random() < 0.3 ? getRandomChar(true) : char))
      .join('');
    setGlitchText(glitchStr);
    // Clear the glitch text after a brief moment.
    setTimeout(() => setGlitchText(''), 50);
  };

  useEffect(() => {
    if (!enabled || hasScrambled) {
      setDisplayText(text);
      return;
    }

    let elapsed = 0;
    isScramblingRef.current = true;

    const timer = setTimeout(() => {
      const scramble = () => {
        const progress = Math.min(elapsed / scrambleDuration, 1);
        const finalCharCount = Math.floor(progress * finalLength);
        let newText = '';

        for (let i = 0; i < finalLength; i++) {
          if (text[i] === ' ') {
            newText += ' ';
          } else {
            newText += i < finalCharCount ? text[i] : CHARACTERS[Math.floor(Math.random() * CHARACTERS.length)];
          }
        }

        setDisplayText(newText);
        elapsed += scrambleSpeed;

        if (progress >= 1) {
          setDisplayText(text);
          isScramblingRef.current = false;
          setHasScrambled(true);
          clearInterval(scrambleIntervalRef.current);
        }
      };

      scrambleIntervalRef.current = setInterval(scramble, scrambleSpeed);
    }, delay);

    return () => {
      clearTimeout(timer);
      clearInterval(scrambleIntervalRef.current);
    };
  }, [enabled, text, finalLength, scrambleDuration, scrambleSpeed, delay, hasScrambled]);

  // Reset hasScrambled when enabled changes to false
  useEffect(() => {
    if (!enabled) {
      setHasScrambled(false);
    }
  }, [enabled]);

  const styles = {
    container: {
      position: 'relative',
      display: 'inline',
      fontFamily: 'Berkeley Mono, monospace',
      whiteSpace: 'pre-wrap'
    },
    text: {
      position: 'relative',
      opacity: 1,
      transform: 'translateY(0)',
      transition: 'opacity 0.5s ease, transform 0.5s ease'
    },
    glitch: {
      position: 'absolute',
      top: 0,
      left: 0,
      transform: 'translateX(-2px)',
      opacity: 0.7,
      color: 'inherit',
      mixBlendMode: 'difference'
    }
  };

  const content = renderText ? renderText(displayText) : displayText;

  return (
    <span style={styles.container} className={className}>
      <span style={styles.text}>{content}</span>
      {glitchText && <span style={styles.glitch}>{glitchText}</span>}
    </span>
  );
};