import React from 'react';
import { motion, useInView } from 'framer-motion';
import { ScrambleText } from '../components/ui/ScrambleText';
import { useRive } from "rive-react";

import { Button } from '../components/ui/Button';

export default function BigShift({ id, onScrollDown }) {
    const ref = React.useRef(null);
    const isInView = useInView(ref, { margin: '-40% 0px -40% 0px' });

    const { rive, RiveComponent } = useRive({
        src: "/spiral-vis-asset.riv",
        autoplay: true,
        stateMachines: "State Machine 1",
        onLoad: () => {
            rive?.play();
        },
    });

    return (
        <motion.section
            id={id}
            ref={ref}
            className="text-neutral-400 w-full min-h-[100svh] flex items-center justify-center snap-start overflow-y-auto relative"
            initial={{ opacity: 0, y: 0 }}
            animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 0 }}
            transition={{ duration: 0.3, ease: "easeOut" }}
        >
            <div className="container max-w-10xl mx-auto flex flex-col md:flex-row items-center justify-center px-5 w-full mt-[-12%]">
                <div className="flex md:mr-16 items-center justify-center -mt-20">
                    <motion.div
                        className="w-[400px] h-[400px] md:h-[500px] md:w-[500px]"
                        initial={{ opacity: 0, y: 60 }}
                        animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
                        transition={{ duration: 0.25, ease: "easeOut", delay: 0.1 }}
                    >
                        <RiveComponent
                            className="w-full h-full"
                        />
                    </motion.div>
                </div>

                <div className="flex flex-col md:pr-10 md:mb-0 w-full md:w-auto text-center md:text-left md:items-start items-center">
                    <h2 className="px-3 py-1 rounded-lg text-xs font-medium bg-[#16B08B]/10 text-[#16B08B] tracking-tight title-font mb-8 w-fit">
                        <ScrambleText text="THE BIG SHIFT" enabled={isInView} />
                    </h2>

                    <motion.h1
                        className="md:text-4xl text-3xl text-white font-mono font-bold leading-[1.25] -tracking-[0.035em] text-center md:text-left"
                        initial={{ opacity: 0, y: 20 }}
                        animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
                        transition={{ duration: 0.5, ease: "easeOut", delay: 0.2 }}
                    >
                        <ScrambleText text="Time for a ground-up rewrite" enabled={isInView} />
                    </motion.h1>

                    <motion.p
                        className="text-sm md:text-[1.15rem] text-[#4F6A63] mt-4 font-mono text-center md:text-left leading-[1.75]"
                        initial={{ opacity: 0, y: 25 }}
                        animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
                        transition={{ duration: 0.5, ease: "easeOut", delay: 0.4 }}
                    >
                        <ScrambleText text="From culture to product, we're overhauling tech" enabled={isInView} />
                    </motion.p>

                </div>
            </div>

        </motion.section>
    );
}