export default function ConnectWithUs() {
    return (
      <div className="py-16">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="lg:py-14 lg:px-20 p-10 rounded-xl bg-gradient-to-r from-teal-600/5 to-teal-900/5 flex items-center justify-between flex-col lg:flex-row">
            <div className="block text-center mb-5 lg:text-left lg:mb-0">
              <h2 className="text-3xl text-neutral-50 font-bold mb-3 lg:mb-2">
              Build with Us
              </h2>
              <p className="text-md text-neutral-400/70 lg:w-full pr-3">
              Join a team that moves fast and makes an impact.
              </p>
            </div>
            <a
              href="https://www.linkedin.com/company/maincodehq/jobs/"
              className="inline-flex h-12 w-auto animate-shimmer items-center justify-center rounded-md border border-[#00CF9D]/10 bg-[#00CF9D]/5 bg-opacity-20 px-6 text-[#ffffff] transition-colors hover:bg-[#00CF9D]/20 focus:outline-none focus:ring-2 focus:ring-[#00CF9D] focus:ring-offset-2 focus:ring-offset-neutral-100 gap-2 text-sm lg:mt-0 text-nowrap"
            >
                Explore Open Positions
            </a>
          </div>
        </div>
      </div>
    );
  }
  