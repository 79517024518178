import { motion } from 'framer-motion';
import { ScrambleText } from './ScrambleText';

export const ChevronDown = ({ onClick, className = '', currentSection }) => {
  // Hide only on the last section
  if (currentSection === 'join-us') return null;

  const isLanding = currentSection === 'landing';

  return (
    <motion.div
      className={`cursor-pointer fixed bottom-12 left-1/2 -translate-x-1/2 flex flex-col items-center gap-2 z-10 ${className}`}
      initial={{ opacity: 0 }}
      animate={{ opacity: isLanding ? 0.3 : 0.15 }}
      whileHover={{ opacity: 0.8 }}
      onClick={onClick}
    >
      {!isLanding && (
        <div className="text-sm font-mono text-[#5f6563]">
          <ScrambleText
            text={`${sections.indexOf(currentSection) + 1}/${sections.length}`}
            scrambleDuration={400}
            scrambleSpeed={30}
            glitchIntensity={0.05}
          />
        </div>
      )}
      <motion.div className="relative">
        <ScrambleText
          text="↓"
          className="text-5xl text-[#16B08B] hover:text-[#01CBA0] transition-colors duration-300"
          scrambleDuration={800}
          scrambleSpeed={50}
          glitchIntensity={0.1}
        />
      </motion.div>
    </motion.div>
  );
};

const sections = ['landing', 'big-shift', 'who-we-are', 'neo', 'join-us']; 