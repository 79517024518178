import React from 'react';
import { ScrambleText } from '../components/ui/ScrambleText';
import { motion } from 'framer-motion';

const BuildersManifesto = () => {
    return (
        <main className="w-full overflow-x-hidden">
            <section className="max-w-7xl mx-auto px-8 sm:px-8 md:px-12 py-16 sm:py-24 mt-20">
                <motion.h1 
                    className="text-2xl sm:text-4xl font-bold mb-8 tracking-tight leading-tight"
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, ease: "easeOut" }}
                >
                    <h1 className="text-2xl sm:text-4xl font-bold mb-8 tracking-tight leading-tight">Builders Manifesto</h1>
                </motion.h1>

                <motion.div 
                    className="mb-8 sm:mb-6"
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.1, ease: "easeOut" }}
                >
                    <h2 className="text-sm sm:text-lg pb-4 text-[#5f6563] font-mono">We build because we must.</h2>

                    <p className="text-sm sm:text-lg mb-8 text-[#5f6563] font-mono">
                        <ScrambleText 
                            text="For most of human history, the tools we used to work were simple extensions of our own capabilities. Then, in the last century, software transformed the world, not by automating work, but by organizing it. The systems we rely on today were built for an era when storing and retrieving data was revolutionary. That era is over."
                            enabled={true}
                            delay={400}
                        />
                    </p>
                </motion.div>

                <motion.div 
                    className="mb-8 sm:mb-6"
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.2, ease: "easeOut" }}
                >
                    <p className="text-sm sm:text-lg mb-8 text-[#5f6563] font-mono">
                        <ScrambleText 
                            text="We now stand at the dawn of truly general intelligence. Systems that merely record and recall are obsolete. We demand technology that understands, anticipates, and acts. Humans excel at creativity, reasoning, and problem-solving, but we struggle with repetition, tedium, and fragmented context. The future isn’t about replacing us. It’s about building tools that amplify us."
                            enabled={true}
                            delay={600}
                        />
                    </p>
                </motion.div>

                <motion.div 
                    className="mb-8 sm:mb-6"
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.2, ease: "easeOut" }}
                >
                    <p className="text-sm sm:text-lg mb-8 text-[#5f6563] font-mono">
                        <ScrambleText 
                            text="Technology must evolve in motion, in real-time, in situ. But reinvention isn’t just about the tools, it’s about the companies that build them. The old structures, rituals, and playbooks are dead weight. Tech has become slow, bloated, performative. Perks, catered lunches, and team-building retreats won’t create the next wave of transformative technology. That infantilization is a smokescreen, a way to make people forget that their work doesn’t matter and their ownership is an illusion."
                            enabled={true}
                            delay={600}
                        />
                    </p>
                </motion.div>

                <motion.div 
                    className="mb-8 sm:mb-6"
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.2, ease: "easeOut" }}
                >
                    <p className="text-sm sm:text-lg mb-8 text-[#5f6563] font-mono">
                        <ScrambleText 
                            text="Every role must ask: Do you directly contribute to what we ship? If you were removed tomorrow, would it matter? If not, you don’t belong here. The modern tech industry is overrun bureaucratic forces that build nothing yet try to control everything. They’ve turned tech into a playground of perks, distractions, and corporate theater. Culture belongs to the dreamers and doers, not the talkers and posers."
                            enabled={true}
                            delay={600}
                        />
                    </p>
                </motion.div>

                <motion.div 
                    className="mb-8 sm:mb-6"
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.2, ease: "easeOut" }}
                >
                    <p className="text-sm sm:text-lg mb-8 text-[#5f6563] font-mono">
                        <ScrambleText 
                            text="We are relentless. We are unapologetic. We’re here to create technology that matters, that customers love, that moves humans forward."
                            enabled={true}
                            delay={600}
                        />
                    </p>
                </motion.div>

                <motion.div 
                    className="mb-8 sm:mb-6"
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.2, ease: "easeOut" }}
                >
                    <p className="text-sm sm:text-lg mb-8 text-[#5f6563] font-mono">
                        <ScrambleText 
                            text="We’re Maincode."
                            enabled={true}
                            delay={600}
                        />
                    </p>
                </motion.div>
            </section>
        </main>
    );
};

export default BuildersManifesto; 