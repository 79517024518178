import React, { useState, useEffect, useCallback } from 'react';
import { ScrambleText } from '../ui/ScrambleText';
import { Link, useLocation, useNavigate } from 'react-router-dom';

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isLogoHovered, setIsLogoHovered] = useState(false);
  const [hoveredLink, setHoveredLink] = useState(null);
  const [navScrambleKey, setNavScrambleKey] = useState(0);
  const [logoScrambleKey, setLogoScrambleKey] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  // Updated navLinks array with only two items
  const navLinks = [
    { 
      name: 'OUR VISION', 
      path: '/about-us',
      onClick: (e) => {
        e.preventDefault();
        if (location.pathname !== '/about-us') {
          navigate('/about-us');
        }
      },
      external: false
    },
    {
      name: 'NEO',
      path: '/neo',
      onClick: (e) => {
        e.preventDefault();
        if (location.pathname !== '/neo') {
          navigate('/neo');
        }
      },
      external: false
    },
    {
      name: 'BLOG',
      path: 'https://blog.maincode.com',
      external: true
    },
    { 
      name: 'JOIN US', 
      path: 'https://www.linkedin.com/company/maincodehq/jobs/',
      external: true
    }
  ];

  // Separate effects for logo and nav links
  useEffect(() => {
    if (isLogoHovered) {
      setLogoScrambleKey(prev => prev + 1);
    }
  }, [isLogoHovered]);

  useEffect(() => {
    if (hoveredLink !== null) {
      setNavScrambleKey(prev => prev + 1);
    }
  }, [hoveredLink]);

  const toggleMenu = useCallback(() => {
    setIsOpen(prev => !prev);
  }, []);

  return (
    <nav className="sticky top-0 z-50 bg-blur-lg backdrop-blur-sm bg-neutral-950">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        {/* Logo */}
        <Link
          to="/home" // Updated path to point to HomePage
          className="flex items-center space-x-3 rtl:space-x-reverse"
          onMouseEnter={() => setIsLogoHovered(true)}
          onMouseLeave={() => setIsLogoHovered(false)}
        >
          <div className="flex items-center">
            <img
              src="/maincode-logo.svg"
              className="h-5 w-auto mr-2"
              alt="Maincode Logo"
            />
            {isLogoHovered ? (
              <ScrambleText
                key={`logo-${logoScrambleKey}`}
                text="MAINCODE"
                scrambleDuration={500}
                scrambleSpeed={25}
                glitchIntensity={0.1}
                enabled={true}
                className="text-[#fafafa] font-mono font-bold text-xl"
              />
            ) : (
              <span className="text-[#fafafa] font-mono font-bold text-xl">
                MAINCODE
              </span>
            )}
          </div>
        </Link>

        {/* Hamburger button for mobile */}
        <button
          onClick={toggleMenu}
          type="button"
          className="md:hidden focus:outline-none"
          aria-controls="navbar-default"
          aria-expanded={isOpen}
        >
          <span className="sr-only">Toggle main menu</span>
          <div className="w-6 h-6 relative">
            <span
              className={`block absolute h-0.5 w-6 bg-neutral-400 transform transition duration-300 ease-in-out ${
                isOpen ? 'rotate-45 top-3' : 'top-1'
              }`}
            />
            <span
              className={`block absolute h-0.5 w-6 bg-neutral-400 transform transition duration-300 ease-in-out ${
                isOpen ? 'opacity-0' : 'top-3'
              }`}
            />
            <span
              className={`block absolute h-0.5 w-6 bg-neutral-400 transform transition duration-300 ease-in-out ${
                isOpen ? '-rotate-45 top-3' : 'top-5'
              }`}
            />
          </div>
        </button>

        {/* Mobile menu */}
        <div className={`${isOpen ? 'block' : 'hidden'} w-full md:block md:w-auto`} id="navbar-default">
          <ul className="font-mono flex flex-col p-4 md:p-1 mt-4 border border-neutral-600/40 rounded-lg bg-black md:bg-transparent md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0">
            {navLinks.map((item, index) => (
              <li
                key={index}
                onMouseEnter={() => setHoveredLink(index)}
                onMouseLeave={() => setHoveredLink(null)}
              >
                {item.external ? (
                  <a
                    href={item.path}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block py-2 px-3 text-[#fafafa] rounded-sm md:hover:bg-transparent md:border-0 md:p-0"
                  >
                    {hoveredLink === index ? (
                      <ScrambleText
                        key={`link-${index}-${navScrambleKey}`}
                        text={item.name}
                        scrambleDuration={250}
                        scrambleSpeed={25}
                        glitchIntensity={0.02}
                        enabled={true}
                        className="text-[#16B08B]"
                      />
                    ) : (
                      <span>{item.name}</span>
                    )}
                  </a>
                ) : (
                  <Link
                    to={item.path}
                    onClick={(e) => item.onClick(e, location)}
                    className="block py-2 px-3 text-[#fafafa] rounded-sm md:hover:bg-transparent md:border-0 md:p-0"
                  >
                    {hoveredLink === index ? (
                      <ScrambleText
                        key={`link-${index}-${navScrambleKey}`}
                        text={item.name}
                        scrambleDuration={250}
                        scrambleSpeed={25}
                        glitchIntensity={0.02}
                        enabled={true}
                        className="text-[#16B08B]"
                      />
                    ) : (
                      <span>{item.name}</span>
                    )}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
}
