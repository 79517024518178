import React from 'react';
import { motion, useInView } from 'framer-motion';
import { ScrambleText } from '../components/ui/ScrambleText';
import { useRive } from "rive-react";

export default function WhoWeAre({ id, onScrollDown }) {
    const ref = React.useRef(null);
    const isInView = useInView(ref, { margin: '-50% 0px -50% 0px' });

    const { rive, RiveComponent } = useRive({
        src: "/planet.riv",
        autoplay: true,
        stateMachines: "State Machine 1",
        onLoad: () => {
            rive?.play();
        },
    });

    return (
        <motion.section 
            id={id}
            ref={ref}
            className="text-neutral-400 w-full min-h-[100svh] flex items-center justify-center snap-start overflow-y-auto relative"
            initial={{ opacity: 0, y: 0 }}
            animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 0 }}
            transition={{ duration: 0.5, ease: "easeOut" }}
        >
            <div className="container px-10 sm:px-8 md:px-32 flex flex-col md:flex-row items-center w-full md:w-[90%] -mt-[12%]">
            <div className="flex md:mr-2 mx-auto items-center justify-center w-full">
                    <motion.div 
                        className="w-[380px] h-[380px] sm:w-[400px] sm:h-[400px] md:h-[500px] md:w-[500px"
                        initial={{ opacity: 0, y: 60 }}
                        animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
                        transition={{ duration: 0.25, ease: "easeOut", delay: 0.1 }}
                    >
                        <RiveComponent 
                            className="w-full h-full"
                        />
                    </motion.div>
                </div>

                <div className="flex flex-col md:pr-10 mb-6 md:mb-0 w-full md:w-auto text-center md:text-left md:items-start items-center sm:-mt-12">
                    <h2 className="px-3  rounded-lg text-xs font-medium bg-[#16B08B]/10 text-[#16B08B] tracking-tight title-font mb-8 w-fit">
                        <ScrambleText text="WHO WE ARE" enabled={isInView} />
                    </h2>

                    <motion.h1 
                        className="md:text-4xl text-3xl text-white font-mono font-bold leading-[1.25] -tracking-[0.035em] text-center md:text-left"
                        initial={{ opacity: 0, y: 20 }}
                        animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
                        transition={{ duration: 0.5, ease: "easeOut", delay: 0.2 }}
                    >
                        <ScrambleText text="Dream big and lift heavy." enabled={isInView} />
                    </motion.h1>

                    <motion.p 
                        className="text-sm md:text-[1.15rem] text-[#4F6A63] mt-4 font-mono text-center md:text-left leading-[1.75]"
                        initial={{ opacity: 0, y: 20 }}
                        animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
                        transition={{ duration: 0.5, ease: "easeOut", delay: 0.4 }}
                    >
                        <ScrambleText text="We know just enough to be dangerous and not enough to know better. If they say it can't be done, it's going in the sprint." enabled={isInView} />
                    </motion.p>

                </div>
            </div>
        </motion.section>
    );}
