import React, { useState } from 'react';
import { ScrambleText } from '../ui/ScrambleText';

const Footer = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <footer className="w-full py-2 sm:py-3 text-center text-[#00CF9D] text-xs sm:text-sm border-t border-black mt-auto">
      <div 
        className="px-4 truncate cursor-pointer"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {isHovered ? (
          <ScrambleText 
            text='maincode@australia:~/melbourne$ echo "© 2024"'
            scrambleDuration={400}
            scrambleSpeed={25}
            glitchIntensity={0.1}
            enabled={true}
          />
        ) : (
          <span className="font-mono">
            maincode@australia:~/melbourne$ echo "© 2024"
          </span>
        )}
      </div>
    </footer>
  );
};

export default Footer;