import Lottie from 'lottie-react';
import ChromeAnimation from '../../Chrome.json';
import TodoAnimation from '../../ToDo.json';
import CopyAnimation from '../../copy.json';


const FeaturesSection = () => {
    const features = [
        {
            title: "Copy/Paste to Context",
            description: "Organise your work, turning scattered tabs into structured workflows.",
            bgColor: "bg-teal-900/20",
            hoverBgColor: "group-hover:bg-teal-900/40",
            animation: CopyAnimation,
        },
        {
            title: "Tabs to Tasks",
            description: "Connect the dots across apps, so you always have the full picture.",
            bgColor: "bg-teal-900/20",
            hoverBgColor: "group-hover:bg-teal-900/40",
            animation: TodoAnimation,
        },
        {
            title: "Your apps, AI-supercharged",
            description: "Plan and structure work how you want. Quickly organizing tasks.",
            bgColor: "bg-teal-900/20",
            hoverBgColor: "group-hover:bg-teal-900/40",
            animation: ChromeAnimation,
        }
    ];

    return (
        <div className="py-20">
            <div className="flex w-full items-center rounded-full pb-20">
                <div className="flex-1 border-b border-teal-900/50"></div>
                <span className="text-neutral-200 text-lg font-semibold leading-8 px-8 py-2">Features</span>
                <div className="flex-1 border-b border-teal-900/50"></div>
            </div>
            <div className="mx-auto max-w-8xl px-12 sm:px-10 lg:px-8">
                <div className="flex justify-center items-top gap-x-5 gap-y-12 lg:gap-y-0 flex-wrap lg:flex-nowrap lg:justify-between lg:gap-x-8">
                    {features.map((feature, index) => (
                        <div key={index} className="relative w-full text-center max-md:max-w-sm max-md:mx-auto group md:w-2/5 lg:w-1/3">
                            <div className={`${feature.bgColor} rounded-xl flex justify-center items-center mb-4 w-20 h-20 mx-auto cursor-pointer transition-all duration-200 ${feature.hoverBgColor} group-hover:scale-105`}>
                                <Lottie
                                    animationData={feature.animation}
                                    loop={true}
                                    style={{ width: 60, height: 60 }}
                                />
                            </div>
                            <h4 className="text-lg font-medium text-neutral-200 mb-2">{feature.title}</h4>
                            <p className="text-xs font-mono text-neutral-600">{feature.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
export { FeaturesSection };